<template>
  <div>
    <b-row>
      <b-col>
        <statistic-card-horizontal
          color="primary"
          class="flex-grow-1"
          icon="UserIcon"
          :statistic="totalEmployees"
          statistic-title="Mitarbeiter insgesamt"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          color="success"
          class="flex-grow-1"
          icon="UserCheckIcon"
          :statistic="freeEmployees"
          statistic-title="Verfügbare Mitarbeiter"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          class="flex-grow-1"
          icon="UserPlusIcon"
          :statistic="busyEmployees"
          statistic-title="Mitarbeiter im Auftrag"
        />
      </b-col>
      <b-col>
        <statistic-card-horizontal
          color="danger"
          class="flex-grow-1"
          icon="UserXIcon"
          :statistic="illEmployees"
          statistic-title="Kranke Mitarbeiter"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <employee-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';
import EmployeeTable from '@/layouts/components/tables/EmployeeTable.vue';
import store from '@/store';

export default {
  components: {
    BRow,
    BCol,
    StatisticCardHorizontal,
    EmployeeTable,
  },
  data() {
    return {
      totalEmployees: 0,
      freeEmployees: 0,
      busyEmployees: 0,
      illEmployees: 0,
    };
  },
  created() {
    this.getEmployeeStatus();
  },
  methods: {
    async getEmployeeStatus() {
      const qs = require('qs');
      const query = qs.stringify(
        {
          pagination: {
            page: 1,
            pageSize: 500,
          },
          fields: ['Verfuegbar'],
        },
        {
          encodeValuesOnly: true,
        }
      );

      let employeeItems = [];

      await this.$http
        .get(`/mitarbeiters?${query}`)
        .then(response => {
          employeeItems = response.data.data;
          this.totalRows = response.data.meta.pagination.total;
        })
        .catch(err => console.log(err.response.data.error.message));
      this.totalEmployees = employeeItems.length;
      employeeItems.forEach(employee => {
        if (employee.Verfuegbar === 'Verfuegbar') this.freeEmployees += 1;
        if (employee.Verfuegbar === 'Im_Auftrag') this.busyEmployees += 1;
        if (employee.Verfuegbar === 'Krank') this.illEmployees += 1;
      });
    },
  },
};
</script>

<style></style>
